import React, { Component, Fragment } from "react";
//import './news.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { apiURL, apiPrefix } from "../../utlis/config";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const SlideView = (propsInfo) => {
  const {
    loading,
    brandList,
    headline,
    rediractUrl,
    categoryList,
    productList,
    onClickEnquiry,
  } = propsInfo;
  return (
    <Fragment>
      <div className="new_arrivals_product_info">
        <div className="container">
          <h3 className="wthree_text_info">
            New <span>Arrivals</span>
          </h3>
        </div>
        <div class="agile_last_double_sectionw3ls">
          <Carousel responsive={responsive}>
            {productList.map((post, indx) => {
              return (
                <div
                  className="men-pro-item simpleCart_shelfItem"
                  style={{ margin: "18px" }}
                >
                  <div className="men-thumb-item">
                    <img
                      src={post.pictursList[0]["url"]}
                      alt=""
                      className="pro-image"
                    />
                    {/* <img src="images/m1.jpg" alt="" className="pro-image-back" /> */}
                    <div className="men-cart-pro" style={{ zIndex: "+999" }}>
                      <div
                        className="inner-men-cart-pro"
                        style={{ zIndex: "+999" }}
                      >
                        <a
                          onClick={() => {
                            rediractUrl(
                              "/product/" +
                                // post.categoryId.sUrl +
                                // "/" +
                                post.fullUrl
                            );
                          }}
                          className="link-product-add-cart"
                          style={{ cursor: "pointer" }}
                        >
                          Quick View
                        </a>
                      </div>
                    </div>
                    <span className="product-new-top">New</span>
                  </div>
                  <div className="item-info-product " style={{ margin: 0 }}>
                    <h4>
                      <a
                        onClick={() => {
                          rediractUrl(
                            "/product/" +
                              //  + post.categoryId.sUrl + "/"
                              post.fullUrl
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {post.name}
                      </a>
                    </h4>
                    <div className="info-product-price">
                      {/* <span className="item_price">$45.99</span>
                    <del>$69.71</del> */}
                    </div>
                    <div className="snipcart-details top_brand_home_details item_add single-item hvr-outline-out button2">
                      {/* <form action="#" method="post">
                      <fieldset> */}
                      <input
                        data-toggle="modal"
                        data-target="#enquiryModal"
                        onClick={() => {
                          onClickEnquiry(true, post);
                        }}
                        type="button"
                        name="Send Enquiry"
                        value="Send Enquiry"
                        placeholder="Send Enquiry"
                        className="button"
                      />
                      {/* </fieldset>
                    </form> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
          <div class="clearfix"></div>
        </div>
      </div>

      <div
        className="new_arrivals_product_info"
        style={{ padding: "0 0 3em 0" }}
      >
        <div className="container">
          <h3 className="wthree_text_info">
            Featured <span> Products</span>
          </h3>
        </div>
        <div class="agile_last_double_sectionw3ls">
          <Carousel responsive={responsive}>
            {categoryList.map((post, indx) => {
              return (
                <div
                  class="men-pro-item simpleCart_shelfItem"
                  style={{ margin: "18px" }}
                >
                  <div
                    onClick={() => {
                      rediractUrl("/products/" + post.sUrl);
                    }}
                    class="men-thumb-item"
                  >
                    <img //   <a href="#">
                      src={`${apiURL}${post.imageUrl}`}
                      alt=""
                      class="pro-image-front"
                    />
                    <img //   <a href="#">
                      src={`${apiURL}${post.imageUrl}`}
                      alt=""
                      class="pro-image-back"
                    />
                  </div>
                  <div class="item-info-product ">
                    <h4>
                      <Link
                        to={"/products/" + post.sUrl}
                        style={{ cursor: "pointer" }}
                      >
                        {post.name}
                      </Link>
                    </h4>
                    <div className="info-product-price">
                      {/* <span className="item_price">$45.99</span>
                    <del>$69.71</del> */}
                    </div>
                    <div class="snipcart-details top_brand_home_details item_add single-item hvr-outline-out button2">
                      <input
                        onClick={() => {
                          rediractUrl("/products/" + post.sUrl);
                        }}
                        type="button"
                        name="View All"
                        value="View All"
                        class="button"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
          <div class="clearfix"></div>
        </div>
      </div>

      <div className="new_arrivals_product_info" style={{ padding: 0 }}>
        <div className="container">
          <h3 className="wthree_text_info">
            Featured <span> Brands</span>
          </h3>
        </div>
        <div
          class="agile_last_double_sectionw3ls"
          id="home-brand"
          style={{ marginBottom: 35 }}
        >
          <Carousel responsive={responsive}>
            {brandList.map((post, indx) => {
              return (
                <div
                  className=" multi-gd-img multi-gd-text m-20"
                  key={indx}
                  // style={{ margin: "60px" }}
                >
                  <Link
                    to={"/brand/" + post.sUrl}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={`${apiURL}${post.imageUrl}`}
                      alt=" "
                      style={{
                        // height: "220px",
                        width: "100%",
                        borderRadius: "8px",
                        //   padding: "10px 20px",
                      }}
                    />
                  </Link>
                </div>
              );
            })}
          </Carousel>
          <div class="clearfix"></div>
        </div>
      </div>
    </Fragment>
  );
};
export default SlideView;
