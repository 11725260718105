import React from "react";
// import Header from "./common/header-component/header";
// import Sidebar from "./common/sidebar-component/sidebar";
// import RightSidebar from "./common/right-sidebar";
// import Footer from "./common/footer";
// import ThemeCustomizer from "./common/theme-customizer";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Loader from "./common/loader";

import Header from "./common/header";
import HeaderBot from "./common/headerBot";
import Footer from "./common/footer";
import NavbarTop from "./common/navbarTop";
import Banner from "./common/banner";
import CouponsGrids from "./common/couponsGrids";
import WeOffer from "./common/weOffer";

const AppLayout = ({ children }) => {
  console.log({ children });
  return (
    <>
      {/* <Loader /> */}
      <Header />
      <HeaderBot />
      <NavbarTop />
      {/* <RightSidebar /> */}
      {children}
      <Footer />
      {/* <ToastContainer /> */}
    </>
  );
};

export default AppLayout;
