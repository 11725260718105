import React, { Fragment } from "react";
import store from "store";
import { apiURL, apiPrefix } from "../../utlis/config";
import { Link } from "react-router-dom";

const Banner = (propsInfo) => {
  console.log({ propsInfo });
  const { title, subTitle } = propsInfo;
  // const
  return (
    <Fragment>
      <div class="page-head_agile_info_w3l">
        <div class="container">
          <h3>
            {title && (
              <>
                {title.split(" ")[0]}
                {title.split(" ").length > 0 && (
                  <span>{title.split(" ").slice(1)}</span>
                )}
              </>
            )}
          </h3>
          <div class="services-breadcrumb">
            <div class="agile_inner_breadcrumb">
              <ul class="w3_short">
                <li>
                  <a href="/">Home</a>
                  <i>|</i>
                </li>
                <li>{subTitle && <>{subTitle}</>}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Banner;
