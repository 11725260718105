import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { apiURL, apiPrefix } from "../../utlis/config";
import { CountryList } from "../../utlis/constant";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// import store from "store";
const EnquiryForm = (propsInfo) => {
  // const { register, handleSubmit, errors } = useForm();
  // console.log({ propsInfo });
  const { modalShow, productInfo, onClickToggle, onClickConfirm } = propsInfo;
  const onSubmit = (data) => {
    if (data !== "") {
      // onSubmitForm()/
      // propsInfo.onClickConfirm(data);
    } else {
      // errors.showMessages();
    }
  };
  return (
    <Fragment>
      {/* <!-- Modal2 --> */}
      <div className="modal fade" id="enquiryModal" tabIndex="-1" role="dialog">
        {modalShow && (
          <div className="modal-dialog">
            {/* <!-- Modal content--> */}
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => onClickToggle}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body modal-body-sub_agile">
                {/* <div className="col-md-2 modal_body_right modal_body_left1"></div> */}
                <div className="col-md-8 modal_body_left modal_body_left1">
                  <h3 className="agileinfo_sign">
                    Enquiry <span>Now</span>
                  </h3>
                  <form onSubmit={onClickConfirm} method="post">
                    <div className="styled-input agile-styled-input-top">
                      <input
                        type="text"
                        name="name"
                        // placeholder="Enter name..."
                        required=""
                      />
                      <label>Name *</label>
                      <span></span>
                    </div>
                    <div className="styled-input">
                      <input
                        type="email"
                        name="email"
                        // placeholder="Enter email..."
                        required=""
                      />
                      <label>Email *</label>
                      <span></span>
                    </div>
                    <div className="styled-input">
                      <input
                        type="number"
                        name="contactNumber"
                        // placeholder="Enter contact..."
                        required=""
                      />
                      <label>Contact * </label>
                      <span></span>
                    </div>
                    <div className="styled-input">
                      <select type="text" name="country" required="">
                        <option value="all"></option>
                        {/* <option value="all">Select country...</option> */}
                        {/* <option value="india">India</option> */}
                        {CountryList.map((data) => {
                          return <option value={data.name}>{data.name}</option>;
                        })}
                      </select>
                      <label> Country *</label>
                      <span></span>
                    </div>
                    <div className="styled-input">
                      <input
                        type="number"
                        name="quantity"
                        // placeholder="Enter quantity..."
                        required=""
                      />
                      <label>Quantity *</label>
                      <span></span>
                    </div>
                    <input
                      type="text"
                      name="productId"
                      value={productInfo._id}
                      hidden
                    />

                    <input
                      type="submit"
                      placeholder="Submit"
                      // data-dismiss="modal"
                    />
                    {/* <Button type="submit">Submit</Button> */}
                  </form>
                  <ul className="social-nav model-3d-0 footer-social w3_agile_social top_agile_third">
                    <li>
                      <a href="#" className="facebook">
                        <div className="front">
                          <i className="fa fa-facebook" aria-hidden="true"></i>
                        </div>
                        <div className="back">
                          <i className="fa fa-facebook" aria-hidden="true"></i>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="twitter">
                        <div className="front">
                          <i className="fa fa-twitter" aria-hidden="true"></i>
                        </div>
                        <div className="back">
                          <i className="fa fa-twitter" aria-hidden="true"></i>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="instagram">
                        <div className="front">
                          <i className="fa fa-instagram" aria-hidden="true"></i>
                        </div>
                        <div className="back">
                          <i className="fa fa-instagram" aria-hidden="true"></i>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="pinterest">
                        <div className="front">
                          <i className="fa fa-linkedin" aria-hidden="true"></i>
                        </div>
                        <div className="back">
                          <i className="fa fa-linkedin" aria-hidden="true"></i>
                        </div>
                      </a>
                    </li>
                  </ul>
                  <div className="clearfix"></div>
                  {/* <p>
                    <a href="#">By clicking register, I agree to your terms</a>
                    Sign up for our newsletter - don't worry, we won't spam your
                    inbox!
                  </p> */}
                </div>
                <div className="col-md-4 modal_body_right modal_body_right1">
                  <img
                    src={require("../../assets/images/enquiry_pic.png")}
                    alt=" "
                  />
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
            {/* <!-- //Modal content--> */}
          </div>
        )}{" "}
      </div>
      {/* <!-- //Modal2 --> */}
    </Fragment>
  );
};

// export default EnquiryForm;

export default EnquiryForm;
