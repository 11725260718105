import React, { Fragment } from "react";
import Header from "../../components/common/header";
import HeaderBot from "../../components/common/headerBot";
import BannerTop from "../../components/common/navbarTop";
import Banner from "../../components/common/banner";
import SamiBanner from "../../components/common/samiBanner";
import CouponsGrids from "../../components/common/couponsGrids";
import HomeService from "../../services/homeService";
import { apiURL } from "../../utlis/config";
import store from "store";
import $ from "jquery";
import { Helmet } from "react-helmet";

class AboutUs extends React.Component {
  componentDidMount() {
    store.set("prvUrl", this.props.location.pathname);
    this.callApiHeader();
  }
  componentDidUpdate() {
    store.set("prvUrl", this.props.location.pathname);
  }
  callApiHeader = () => {
    HomeService.fetchBrandCategoryInfo().then((response) => {
      if (!response.error) {
        var header = store.get("header");
        this.updateEvent(header);
      }
    });
  };
  updateEvent = (headerInfo) => {
    var event = new CustomEvent("headerInfo", {
      detail: { headerInfo: headerInfo },
    });
    document.dispatchEvent(event);
  };
  render() {
    return (
      <Fragment>
        <SamiBanner title={"About Us"} subTitle={"About"} />
        <div class="banner_bottom_agile_info">
          <div class="container">
            <div class="agile_ab_w3ls_info">
              <div class="col-md-6 ab_pic_w3ls">
                <img src="images/ab_pic.jpg" alt=" " class="img-responsive" />
              </div>
              <div class="col-md-6 ab_pic_w3ls_text_info">
                <h5 style={{ margin: 0 }}>
                  About <span>Us</span>{" "}
                </h5>
                <p>
                  Incorporated on 11th August, 2020, Arab Tech Store houses all
                  the technological solutions for businesses and households, to
                  help refine their lives and compliment their efforts in all
                  that they wish to undertake. The company is based in the city
                  of Qatar, Dubai but ships worldwide/ nation-wide.
                </p>
              </div>
              <div class="clearfix"></div>
            </div>

            <div class="agile_ab_w3ls_info">
              <div class="col-md-12 ab_pic_w3ls_text_info">
                <h5 style={{ margin: 0 }}>
                  Vision <span> </span>
                </h5>
                <p>
                  With a diverse offering, we wish to serve as a ‘one-stop
                  destination’ for all shoppers looking for technology-based
                  solutions to augment their day-to-day experiences, reduce the
                  efforts and time spent in verifying the quality of
                  online-bought products and overhaul the customer shopping
                  experience to incorporate convenience and certainty.
                </p>
              </div>
              <div class="col-md-12 ab_pic_w3ls_text_info">
                <h5 style={{ margin: 0 }}>
                  Mission <span> </span>
                </h5>
                <p>
                  To equip all, including small retailers and households, with
                  the power of automation and smart machinery to improve the
                  efficiency of their business models or ease up their
                  day-to-day chores.
                </p>
              </div>
              <div class="col-md-12 ab_pic_w3ls_text_info">
                <h5 style={{ margin: 0 }}>
                  Goals <span> </span>
                </h5>
                <p style={{ margin: "1em 0" }}>
                  We continuously strive to achieve our vision by segmenting our
                  journey into measurable milestones and quantifying our
                  outcomes.
                </p>
                <p style={{ margin: "1em 0" }}>
                  - To continuously expand our inventory and provide customers
                  with a plethora of technological innovations to choose from
                </p>
                <p style={{ margin: "1em 0" }}>
                  - To enhance our customers’ shopping experience through
                  featured products, free shipping and free gift coupons, among
                  others.
                </p>
                <p style={{ margin: "1em 0" }}>
                  - To provide quality after-sales service in the form of 24/7
                  support, money back guarantee, among others
                </p>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>

        {/* <div class="banner_bottom_agile_info team">
          <div class="container">
            <h3 class="wthree_text_info">
              Our Team <span>Members</span>
            </h3>
            <div class="inner_w3l_agile_grids">
              <div class="col-md-3 team-grids">
                <div class="thumbnail team-w3agile">
                  <img src="images/t1.jpg" class="img-responsive" alt="" />
                  <div class="social-icons team-icons right-w3l fotw33">
                    <div class="caption">
                      <h4>Joanna Vilken</h4>
                      <p>Add Short Description</p>
                    </div>
                    <ul class="social-nav model-3d-0 footer-social w3_agile_social two">
                      <li>
                        <a href="#" class="facebook">
                          <div class="front">
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                          </div>
                          <div class="back">
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#" class="twitter">
                          <div class="front">
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </div>
                          <div class="back">
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#" class="instagram">
                          <div class="front">
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                          </div>
                          <div class="back">
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#" class="pinterest">
                          <div class="front">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </div>
                          <div class="back">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3 team-grids">
                <div class="thumbnail team-w3agile">
                  <img src="images/t2.jpg" class="img-responsive" alt="" />
                  <div class="social-icons team-icons right-w3l fotw33">
                    <div class="caption">
                      <h4>Anika Mollik</h4>
                      <p>Add Short Description</p>
                    </div>
                    <ul class="social-nav model-3d-0 footer-social w3_agile_social two">
                      <li>
                        <a href="#" class="facebook">
                          <div class="front">
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                          </div>
                          <div class="back">
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#" class="twitter">
                          <div class="front">
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </div>
                          <div class="back">
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#" class="instagram">
                          <div class="front">
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                          </div>
                          <div class="back">
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#" class="pinterest">
                          <div class="front">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </div>
                          <div class="back">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3 team-grids">
                <div class="thumbnail team-w3agile">
                  <img src="images/t3.jpg" class="img-responsive" alt="" />
                  <div class="social-icons team-icons right-w3l fotw33">
                    <div class="caption">
                      <h4>Megali Deo</h4>
                      <p>Add Short Description</p>
                    </div>
                    <ul class="social-nav model-3d-0 footer-social w3_agile_social two">
                      <li>
                        <a href="#" class="facebook">
                          <div class="front">
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                          </div>
                          <div class="back">
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#" class="twitter">
                          <div class="front">
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </div>
                          <div class="back">
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#" class="instagram">
                          <div class="front">
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                          </div>
                          <div class="back">
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#" class="pinterest">
                          <div class="front">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </div>
                          <div class="back">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3 team-grids">
                <div class="thumbnail team-w3agile">
                  <img src="images/t4.jpg" class="img-responsive" alt="" />
                  <div class="social-icons team-icons right-w3l fotw33">
                    <div class="caption">
                      <h4>Retas Word</h4>
                      <p>Add Short Description</p>
                    </div>
                    <ul class="social-nav model-3d-0 footer-social w3_agile_social two">
                      <li>
                        <a href="#" class="facebook">
                          <div class="front">
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                          </div>
                          <div class="back">
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#" class="twitter">
                          <div class="front">
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </div>
                          <div class="back">
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#" class="instagram">
                          <div class="front">
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                          </div>
                          <div class="back">
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#" class="pinterest">
                          <div class="front">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </div>
                          <div class="back">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="clearfix"> </div>
            </div>
          </div>
        </div> */}

        {/* <CouponsGrids /> */}
      </Fragment>
    );
  }
}

export default AboutUs;
