// src/reusable/image-gallery.component.js
import React, { Fragment } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Header from "../../components/common/header";
import HeaderBot from "../../components/common/headerBot";
import BannerTop from "../../components/common/navbarTop";
import SamiBanner from "../../components/common/samiBanner";
import Loader from "../../components/common/loader";
import CouponsGrids from "../../components/common/couponsGrids";
import WeOffer from "../../components/common/weOffer";
import ProductLists from "../../components/common/productLists";
import PageHeadAgileInfo from "../../components/common/pageHeadAgileInfo";
import EnquiryForm from "../../components/common/enquiryForm";
import HomeService from "../../services/homeService";
import ProductService from "../../services/productService";
import { apiURL } from "../../utlis/config";
import store from "store";
import $ from "jquery";
import { Helmet } from "react-helmet";

class ImageGallaryComponent extends React.Component {
  state = {
    dataInfo: {},
    brandList: [],
    categoryList: [],
    reload: true,
  };
  componentDidMount() {
    this.callApiHeader();
  }
  componentDidMount() {
    store.set("prvUrl", this.props.location.pathname);
    const { match } = this.props;
    const { params } = match;
    console.log(this.props, "props----", { params });
    if (params.productId) {
      this.setState({ productId: params.productId }, function () {
        this.callApiHeader();
        this.callApiInfo();
      });
    }
  }
  componentDidUpdate() {
    store.set("prvUrl", this.props.location.pathname);
  }
  callApiHeader = () => {
    HomeService.fetchBrandCategoryInfo().then((response) => {
      if (!response.error) {
        var header = store.get("header");
        this.updateEvent(header);
      }
    });
  };
  updateEvent = (headerInfo) => {
    var event = new CustomEvent("headerInfo", {
      detail: { headerInfo: headerInfo },
    });
    document.dispatchEvent(event);
  };
  callApiInfo = () => {
    const { productId, reload } = this.state;
    ProductService.fetchProductsInfo(productId).then((response) => {
      if (!response.error) {
        if (reload) {
          // window.location.reload(false);
        }
        this.setState({
          dataInfo: response.data,
          reload: false,
        });
      } else {
        this.props.history.push("/product");
      }
    });
    this.setState({ loader: false });
  };
  onChangeEvent = () => {
    console.log("onChange Event Triggered");
  };

  onClickItemEvent = () => {
    console.log("onClickItem Event Triggered");
  };

  onClickThumbEvent = () => {
    console.log("onClickThumb Event Triggered");
  };

  onSwipeStartEvent = () => {
    console.log("onSwipeStart Event Triggered");
  };

  onSwipeEndEvent = () => {
    console.log("onSwipeEnd Event Triggered");
  };

  onSwipeMoveEvent = () => {
    console.log("onSwipeMove Event Triggered");
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const formData = {};
    formData["name"] = data.get("name");
    formData["email"] = data.get("email");
    formData["contactNumber"] = data.get("contactNumber");
    formData["countryCode"] = data.get("countryCode");
    formData["country"] = data.get("country");
    formData["quantity"] = data.get("quantity");
    formData["productId"] = data.get("productId");
    this.setState({ loader: true }, function () {
      ProductService.sendEnqury(formData).then((response) => {
        this.setState({ loader: false, showEnquiryFormModal: false });
        if (!response.error) {
          this.props.history.push("/thanks");
        } else {
        }
      });
    });
  };
  render() {
    const { dataInfo, brandList, loader } = this.state;
    const propsEnquiryForm = {
      productInfo: dataInfo,
      onClickConfirm: this.handleSubmit,
    };
    return (
      <div>
        {dataInfo && (
          <>
            <SamiBanner title={dataInfo.name} subTitle={dataInfo.name} />
            <Helmet>
              <meta charSet="utf-8" />
              <title>{dataInfo.sTitle}</title>
              <link
                rel="canonical"
                href={`${"https://arabtechstore.com/"}product/${
                  dataInfo.fullUrl
                }`}
              />
              <meta name="keyword" content={dataInfo.sKeyword} />
              <meta name="description" content={dataInfo.shortDescription} />
            </Helmet>
            <div class="banner-bootom-w3-agileits">
              {loader && <Loader />}
              <div class="container">
                <div
                  class="col-md-6 single-right-left simpleCart_shelfItem"
                  style={{ marginBottom: 20 }}
                >
                  <h3>{dataInfo.name}</h3>
                </div>
              </div>

              <div class="container">
                <div class="col-md-6 single-right-left ">
                  <div class="grid images_4_of_2">
                    <Carousel
                      autoPlay
                      interval="5000"
                      infiniteLoop
                      transitionTime="1000"
                      onChange={this.onChangeEvent}
                      onClickItem={this.onClickItemEvent}
                      onClickThumb={this.onClickThumbEvent}
                      onSwipeStart={this.onSwipeStartEvent}
                      onSwipeEnd={this.onSwipeEndEvent}
                      onSwipeMove={this.onSwipeMoveEvent}
                    >
                      {dataInfo &&
                        dataInfo.pictursList &&
                        dataInfo.pictursList.length > 0 &&
                        dataInfo.pictursList.map((item) => {
                          const props = {
                            width: 400,
                            height: 250,
                            zoomWidth: 500,
                            img: item.url,
                          };
                          return (
                            <div
                              data-thumb={`${item.url}`}
                              class="clone"
                              aria-hidden="true"
                            >
                              <img src={` ${item.url}`} />
                            </div>
                          );
                        })}
                    </Carousel>
                  </div>
                </div>
                <div class="col-md-6 single-right-left simpleCart_shelfItem">
                  <EnquiryForm {...propsEnquiryForm} />
                </div>
                <div class="clearfix"></div>
                {/* <!-- /new_arrivals -->  */}
                <div class="responsive_tabs_agileits">
                  <div class="container">
                    <div class="col-md-12 single-right-left simpleCart_shelfItem">
                      <h3>{"Description"}</h3>
                    </div>
                    <div
                      class="col-md-12 single-right-left simpleCart_shelfItem"
                      style={{ margin: 20 }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: dataInfo.description,
                        }}
                      ></div>
                    </div>
                  </div>
                  {dataInfo && dataInfo.pdfFileUrl && (
                    <div class="container">
                      <div class="col-md-12 single-right-left simpleCart_shelfItem">
                        <h3>{"Product PDF"}</h3>
                      </div>
                      <div
                        class="col-md-12 single-right-left simpleCart_shelfItem"
                        style={{ margin: 20 }}
                      >
                        <a
                          onClick={() => {
                            window.open(
                              `${apiURL}${dataInfo.pdfFileUrl}`,
                              "_blank"
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {dataInfo.pdfFileName}
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <ProductLists />
          </>
        )}
        {/* <CouponsGrids /> */}
      </div>
    );
  }
}

export default ImageGallaryComponent;
