import $ from "jquery";
import { toast } from "react-toastify";
import { apiURL } from "../utlis/config";
import store from "store";
// const apiURL = "http://172.105.35.119:8080/api";
var Actions = {
  fetchProductsInfo: (
    productId,
    productStatus,
    page,
    pageSize,
    searchText,
    startDate,
    endDate
  ) => {
    console.log(
      { pageSize, page, searchText, startDate, endDate },
      "products=-----@"
    );
    //console.log("startDate  ==", startDate !== "" ? searchText : undefined)

    toast.dismiss();
    return new Promise((resolve, reject) => {
      var url = apiURL + "/products";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "GET",
        headers: {
          Authorization: "Bearer " + store.get("authToken"),
          // fngprt: m.cookie.get("fngprt")
        },
        data: {
          productId: productId,
          productStatus: productStatus,
          limit: pageSize,
          page: page,
          searchText: searchText !== "" ? searchText : undefined,
          startDate:
            startDate !== "" && startDate !== "Invalid date"
              ? startDate
              : undefined,
          endDate:
            endDate !== "" && endDate !== "Invalid date" ? endDate : undefined,
        },
        success: function (response) {
          resolve(response);
        },
        error: function (error) {
          if (error.status == 401) {
            toast.error(
              "Your session has been expired. Please relogin.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            toast.error(
              "Some error has occurred.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          }
        },
      };
      $.ajax(settings).done();
    });
  },
  fetchBrandsInfo: (
    brandStatus,
    page,
    pageSize,
    searchText,
    startDate,
    endDate
  ) => {
    return new Promise((resolve, reject) => {
      var url = apiURL + "/brands";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "GET",
        headers: {
          Authorization: "Bearer " + store.get("authToken"),
          // fngprt: m.cookie.get("fngprt")
        },
        data: {
          brandStatus: brandStatus,
          limit: pageSize,
          page: page,
          searchText: searchText !== "" ? searchText : undefined,
          startDate:
            startDate !== "" && startDate !== "Invalid date"
              ? startDate
              : undefined,
          endDate:
            endDate !== "" && endDate !== "Invalid date" ? endDate : undefined,
        },
        success: function (response) {
          if (!response.error) {
            toast.success(
              response.memberMessage,
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          } else {
            toast.error(
              response.memberMessage,
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          }
          resolve(response);
        },
        error: function (error) {
          if (error.status == 401) {
            toast.error(
              "Your session has been expired. Please relogin.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            toast.error(
              "Some error has occurred.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          }
        },
      };
      $.ajax(settings).done();
    });
  },
  fetchBrandCategoryInfo: () => {
    toast.dismiss();
    return new Promise((resolve, reject) => {
      var url = apiURL + "/home/header";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "GET",
        // headers: {
        //   Authorization: "Bearer " + store.get("authToken"),
        //   // fngprt: m.cookie.get("fngprt")
        // },
        success: function (response) {
          if (!response.error) {
            response.data = undefined;
            response.error = undefined;
            response.errors = undefined;
            response.memberMessage = undefined;
            response.status = undefined;
            store.set("header", response);
          }
          resolve(response);
        },
        error: function (error) {
          if (error.status == 401) {
            toast.error(
              "Your session has been expired. Please relogin.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            toast.error(
              "Some error has occurred.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          }
        },
      };
      $.ajax(settings).done();
    });
  },

  fetchCategoriesInfo: (
    categoryStatus,
    page,
    pageSize,
    searchText,
    startDate,
    endDate
  ) => {
    //console.log({ pageSize, page, searchText, startDate, endDate });
    //console.log("startDate  ==", startDate !== "" ? searchText : undefined)

    toast.dismiss();
    return new Promise((resolve, reject) => {
      var url = apiURL + "/categories";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "GET",
        headers: {
          Authorization: "Bearer " + store.get("authToken"),
          // fngprt: m.cookie.get("fngprt")
        },
        data: {
          categoryStatus: categoryStatus,
          limit: pageSize,
          page: page,
          searchText: searchText !== "" ? searchText : undefined,
          startDate:
            startDate !== "" && startDate !== "Invalid date"
              ? startDate
              : undefined,
          endDate:
            endDate !== "" && endDate !== "Invalid date" ? endDate : undefined,
        },
        success: function (response) {
          if (!response.error) {
            toast.success(
              response.memberMessage,
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          } else {
            toast.error(
              response.memberMessage,
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          }
          resolve(response);
        },
        error: function (error) {
          if (error.status == 401) {
            toast.error(
              "Your session has been expired. Please relogin.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            toast.error(
              "Some error has occurred.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          }
        },
      };
      $.ajax(settings).done();
    });
  },
  fetchBannerImagesInfo: (
    bannerImageStatus,
    page,
    pageSize,
    searchText,
    startDate,
    endDate
  ) => {
    //console.log({ pageSize, page, searchText, startDate, endDate });
    //console.log("startDate  ==", startDate !== "" ? searchText : undefined)

    toast.dismiss();
    return new Promise((resolve, reject) => {
      var url = apiURL + "/banner/image";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "GET",
        headers: {
          Authorization: "Bearer " + store.get("authToken"),
          // fngprt: m.cookie.get("fngprt")
        },
        data: {
          bannerImageStatus: bannerImageStatus,
          limit: pageSize,
          page: page,
          searchText: searchText !== "" ? searchText : undefined,
          startDate:
            startDate !== "" && startDate !== "Invalid date"
              ? startDate
              : undefined,
          endDate:
            endDate !== "" && endDate !== "Invalid date" ? endDate : undefined,
        },
        success: function (response) {
          if (!response.error) {
            toast.success(
              response.memberMessage,
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          } else {
            toast.error(
              response.memberMessage,
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          }
          resolve(response);
        },
        error: function (error) {
          if (error.status == 401) {
            toast.error(
              "Your session has been expired. Please relogin.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            toast.error(
              "Some error has occurred.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          }
        },
      };
      $.ajax(settings).done();
    });
  },
};

export default Actions;
