import React, { Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// import Profile from "./memberProfile";
import Home from "./home";
import ProductView from "./productView";
import SearchView from "./searchView";
import CategoryView from "./categoryView";
import BrandView from "./brandView";
import ContactUs from "./contactUs";
import AboutUs from "./aboutUs";
import ThanksView from "./thanksView";

const App = ({ match }) => (
  <Fragment>
    <Switch>
      <Route
        path={`${match.url}product/:brandId/:categoryId/:productId`}
        component={ProductView}
      />
      <Route
        path={`${match.url}products/:categoryUrl`}
        component={CategoryView}
      />
      <Route path={`${match.url}brand/:brandUrl`} component={BrandView} />
      <Route path={`${match.url}product`} component={ProductView} />
      <Route path={`${match.url}contact`} component={ContactUs} />
      <Route path={`${match.url}search/:searchValue`} component={SearchView} />
      <Route path={`${match.url}search`} component={SearchView} />
      <Route path={`${match.url}about`} component={AboutUs} />
      <Route path={`${match.url}thanks`} component={ThanksView} />
      <Route path={`${match.url}`} component={Home} />
    </Switch>
  </Fragment>
);

export default App;
