import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Footer = (footerInfo) => {
  console.log({ footerInfo });

  return (
    <Fragment>
      {" "}
      <div className="footer">
        <div className="footer_agile_inner_info_w3l">
          <div className="col-md-4 footer-left">
            <Link to="/">
              <i className="fa fa-ats-white-logo-icon"></i>
              {/* <span>E</span>lite Shoppy */}
            </Link>
            <h2></h2>
            <p>
              {/* Lorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed
              quia non numquam eius modi tempora. */}
            </p>
            <ul className="social-nav model-3d-0 footer-social w3_agile_social two">
              <li></li>
              <li></li>
              <li></li>
              <li>
                <a
                  href="https://www.facebook.com/Audio-Rental-Audiobot-Store-Trading-100857949429326/?ti=as"
                  className="facebook"
                  target="_blank"
                >
                  <div className="front">
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </div>
                  <div className="back">
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </div>
                </a>
              </li>
              {/* <li>
                <a href="#" className="twitter" target="_blank">
                  <div className="front">
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </div>
                  <div className="back">
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </div>
                </a>
              </li>
              <li>
                <a href="#" className="instagram" target="_blank">
                  <div className="front">
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </div>
                  <div className="back">
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </div>
                </a>
              </li>
              <li>
                <a href="#" className="pinterest" target="_blank">
                  <div className="front">
                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                  </div>
                  <div className="back">
                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                  </div>
                </a>
              </li> */}
            </ul>
          </div>
          <div className="col-md-8 footer-right">
            <div className="sign-grds">
              <div className="col-md-6 sign-gd">
                <h4>
                  Our <span>Information</span>
                </h4>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/products">Product</Link>
                  </li>
                  <li>
                    <Link to="/brand">Brand</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>

              <div className="col-md-6 sign-gd-two">
                <h4>
                  Store <span>Information</span>
                </h4>
                <div className="w3-address">
                  {/* <div className="w3-address-grid">
                    <div className="w3-address-left">
                      <i className="fa fa-phone" aria-hidden="true"></i>
                    </div>
                    <div className="w3-address-right">
                      <h6>Phone Number</h6>
                      <p>+91-800 4000 008</p>
                    </div>
                    <div className="clearfix"> </div>
                  </div> */}
                  <div className="w3-address-grid">
                    <div className="w3-address-left">
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                    </div>
                    <div className="w3-address-right">
                      <h6>Email Address</h6>
                      <p>
                        Email:{" "}
                        <a href="mailto:salescoordinator@arabtechstore.com">
                          salescoordinator@arabtechstore.com
                        </a>
                      </p>
                    </div>
                    <div className="clearfix"> </div>
                  </div>
                  <div className="w3-address-grid">
                    <div className="w3-address-left">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                    </div>
                    <div className="w3-address-right">
                      <h6>Location</h6>
                      <p>
                        Office No 106, First Floor,
                        <span>Bin Alshaikh Holding Bank Street,</span>
                        <span>Doha, Qatar.</span>
                      </p>
                    </div>
                    <div className="clearfix"> </div>
                  </div>
                </div>
              </div>

              <div className="clearfix"></div>
            </div>
          </div>
          <div className="clearfix"></div>

          <p className=" copy-right m-t-20">
            © 2020-{new Date().getFullYear()} Arab Tech Store. All Rights
            Reserved | Developed & Designed By{" "}
            <a href="http://gaviralgamtec.com/" target="_blank">
              Gaviral Gamtec Private Limited.
            </a>
          </p>
        </div>
      </div>
      <a
        href="#home"
        className="scroll"
        id="toTop"
        style={{ display: "block" }}
      >
        <span id="toTopHover" style={{ opacity: 1 }}></span>
      </a>
    </Fragment>
  );
};

export default Footer;
