import React, { Fragment } from "react";
import store from "store";
import { useLocation, withRouter } from "react-router";
import { Link } from "react-router-dom";
var headerInfo = store.get("header");
class NavbarTop extends React.Component {
  state = { headerInfo: {} };
  componentDidMount() {
    console.log(this.props, "props --headerInfo-**@@***---");
    this.setState({ headerInfo: store.get("header") });
    var self = this;
    document.addEventListener("headerInfo", function (e) {
      // Prints "Example of an event"
      headerInfo = e.detail.headerInfo;
      console.log({ headerInfo }, "headerInfo ---*****---");
      // setheaderInfo(headerInfo);
      self.setState({ headerInfo });
    });
  }
  componentDidUpdate() {
    console.log(this.props, "props --headerInfo-**@@***---");
  }
  render() {
    const { headerInfo } = this.state;
    const { location } = this.props;
    const { pathname } = location;
    console.log({ headerInfo }, this.props, "headerInfo ---**@@***---");
    var categoryList1 = [];
    var categoryList2 = [];
    var brandList1 = [];
    var brandList2 = [];
    // const header = store.get("header");
    if (headerInfo) {
      const { brandList, categoryList } = headerInfo;
      if (categoryList && categoryList.length > 9) {
        // categoryList.reverse();
        categoryList2 = categoryList.slice(0, 9);
        categoryList1 = categoryList.slice(9, categoryList.length);
        // categoryList2.reverse();
        // categoryList1.reverse();
      } else {
        categoryList1 = categoryList;
      }
      if (brandList && brandList.length > 9) {
        // brandList.reverse();
        brandList1 = brandList.slice(0, 9);
        brandList2 = brandList.slice(9, brandList.length);
        // brandList2.reverse();
        // brandList1.reverse();
      } else {
        brandList1 = brandList;
      }
    }
    return (
      <Fragment>
        <div className="ban-top">
          <div className="container">
            <div className="top_nav_left">
              <nav className="navbar navbar-default">
                <div className="container-fluid">
                  {/* <!-- Brand and toggle get grouped for better mobile display --> */}
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle collapsed"
                      data-toggle="collapse"
                      data-target="#bs-example-navbar-collapse-1"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                  </div>
                  {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
                  <div
                    className="collapse navbar-collapse menu--shylock"
                    id="bs-example-navbar-collapse-1"
                  >
                    {/* str.includes("world") */}
                    <ul className="nav navbar-nav menu__list">
                      <li
                        className={
                          pathname === "/"
                            ? "active menu__item menu__item--current"
                            : "menu__item  "
                        }
                      >
                        <Link to={`/`} className="menu__link">
                          Home <span className="sr-only">(current)</span>
                        </Link>
                        {/* <a className="menu__link" href="index.html"></a> */}
                      </li>
                      <li
                        className={
                          pathname === "/about"
                            ? "active menu__item menu__item--current"
                            : "menu__item  "
                        }
                      >
                        <Link className="menu__link" to={"/about"}>
                          About
                        </Link>
                      </li>

                      <li
                        className={
                          pathname === "/product" ||
                          pathname.includes("/product")
                            ? "active dropdown menu__item menu__item--current"
                            : " dropdown menu__item  "
                        }
                      >
                        {/* <li className="dropdown menu__item"> */}
                        <a
                          href="#"
                          className="dropdown-toggle menu__link"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Product's <span className="caret"></span>
                        </a>
                        <ul
                          className="dropdown-menu multi-column columns-3"
                          style={
                            categoryList2.length === 0
                              ? { minWidth: "580px" }
                              : {}
                          }
                        >
                          <div className="agile_inner_drop_nav_info">
                            <div
                              className="col-sm-3 multi-gd-text"
                              style={
                                categoryList2.length === 0
                                  ? { marginLeft: "8%" }
                                  : {}
                              }
                            >
                              <ul className="multi-column-dropdown">
                                {categoryList1 &&
                                  categoryList1.length > 0 &&
                                  categoryList1.map((items) => {
                                    return (
                                      <li>
                                        <Link to={`/products/${items.sUrl}`}>
                                          {items.name}
                                        </Link>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                            {categoryList2 && categoryList2.length > 0 && (
                              <div className="col-sm-3 multi-gd-text">
                                <ul className="multi-column-dropdown">
                                  {categoryList2 &&
                                    categoryList2.length > 0 &&
                                    categoryList2.map((items) => {
                                      return (
                                        <li>
                                          <Link to={`/products/${items.sUrl}`}>
                                            {items.name}
                                          </Link>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            )}
                            <div
                              className={
                                categoryList2.length > 0
                                  ? "col-sm-6 multi-gd-img   "
                                  : "col-sm-7 multi-gd-img   m-l-30 "
                              }
                            >
                              <img
                                src={require("../../assets/images/product_img.png")}
                                alt=" "
                              />
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        </ul>

                        {/* <ul
                          className="dropdown-menu multi-column columns-3" 
                        >
                          <div className="agile_inner_drop_nav_info">
                            <div
                              className="col-sm-3 multi-gd-text"
                              style={
                                categoryList2.length === 0
                                  ? { marginLeft: "8%" }
                                  : {}
                              }
                            >
                              <ul className="multi-column-dropdown">
                                {categoryList1 &&
                                  categoryList1.length > 0 &&
                                  categoryList1.map((items) => {
                                    return (
                                      <li>
                                        <Link to={`/products/${items.sUrl}`}>
                                          {items.name}
                                        </Link>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                            {categoryList2.length > 0 && (
                              <div className="col-sm-3 multi-gd-text">
                                <ul className="multi-column-dropdown">
                                  {categoryList2 &&
                                    categoryList2.length > 0 &&
                                    categoryList2.map((items) => {
                                      return (
                                        <li>
                                          <Link to={`/products/${items.sUrl}`}>
                                            {items.name}
                                          </Link>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            )}
                            <div
                              className={
                                categoryList2.length > 0
                                  ? "col-sm-6 multi-gd-img  "
                                  : "col-sm-7 multi-gd-img  m-l-30 "
                              }
                            >
                              <img
                                src={require("../../assets/images/product_img.png")}
                                alt=" "
                              />
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        </ul>
                       */}
                      </li>
                      <li
                        className={
                          pathname === "/brand" || pathname.includes("/brand")
                            ? "active dropdown menu__item menu__item--current"
                            : " dropdown menu__item  "
                        }
                      >
                        <a
                          href="#"
                          className="dropdown-toggle menu__link"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Brand's <span className="caret"></span>
                        </a>
                        <ul
                          className="dropdown-menu multi-column columns-3"
                          style={
                            brandList2.length === 0 ? { minWidth: "580px" } : {}
                          }
                        >
                          <div className="agile_inner_drop_nav_info">
                            <div
                              className="col-sm-3 multi-gd-text"
                              style={
                                brandList2.length === 0
                                  ? { marginLeft: "8%" }
                                  : {}
                              }
                            >
                              <ul className="multi-column-dropdown">
                                {brandList1 &&
                                  brandList1.length > 0 &&
                                  brandList1.map((items) => {
                                    return (
                                      <li>
                                        <Link to={`/brand/${items.sUrl}`}>
                                          {items.name}
                                        </Link>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                            {brandList2 && brandList2.length > 0 && (
                              <div className="col-sm-3 multi-gd-text">
                                <ul className="multi-column-dropdown">
                                  {brandList2 &&
                                    brandList2.length > 0 &&
                                    brandList2.map((items) => {
                                      return (
                                        <li>
                                          <Link to={`/brand/${items.sUrl}`}>
                                            {items.name}
                                          </Link>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            )}
                            <div
                              className={
                                brandList2.length > 0
                                  ? "col-sm-6 multi-gd-img   "
                                  : "col-sm-7 multi-gd-img   m-l-30 "
                              }
                            >
                              <img
                                src={require("../../assets/images/brand_img.png")}
                                alt=" "
                              />
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        </ul>
                      </li>
                      {/* <li className="menu__item dropdown">
                      <a
                        href="#"
                        className="dropdown-toggle menu__link"
                        data-toggle="dropdown"
                      >
                        Short Codes <b className="caret"></b>
                      </a>
                      <ul className="dropdown-menu agile_short_dropdown">
                        <li>
                          <a href="icons.html">Web Icons</a>
                        </li>
                        <li>
                          <a href="typography.html">Typography</a>
                        </li>
                      </ul>
                    </li> */}
                      <li
                        className={
                          pathname === "/contact"
                            ? "active menu__item menu__item--current"
                            : "menu__item  "
                        }
                      >
                        <Link className="menu__link" to={"/contact"}>
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
            {/* <div className="top_nav_right">
            <div className="wthreecartaits wthreecartaits2 cart cart box_1">
              <form action="#" method="post" className="last">
                <input type="hidden" name="cmd" placeholder="_cart" />
                <input type="hidden" name="display" placeholder="1" />
                <button
                  className="w3view-cart"
                  type="submit"
                  name="submit"
                  placeholder=""
                >
                  <i className="fa fa-cart-arrow-down" aria-hidden="true"></i>
                </button>
              </form>
            </div>
          </div> */}
            <div className="clearfix"></div>
          </div>
        </div>
      </Fragment>
    );
  }
}

// export default NavbarTop;

export default withRouter(NavbarTop);
