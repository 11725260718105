import React, { Fragment } from "react";
import store from "store";
import { apiURL, apiPrefix } from "../../utlis/config";
import { Link } from "react-router-dom";

const Banner = (bannerInfo) => {
  console.log({ bannerInfo });
  const { bannerList } = bannerInfo;
  // const
  return (
    <Fragment>
      <div id="myCarousel" className="carousel slide" data-ride="carousel">
        {/* <!-- Indicators --> */}
        <ol className="carousel-indicators">
          {bannerList &&
            bannerList.length > 0 &&
            bannerList.map((items, index) => {
              return (
                <li
                  data-target="#myCarousel"
                  data-slide-to={index}
                  className={index === 0 ? "active" : ""}
                ></li>
              );
            })}
          {/* <li data-target="#myCarousel" data-slide-to="1" className=""></li>
          <li data-target="#myCarousel" data-slide-to="2" className=""></li>
          <li data-target="#myCarousel" data-slide-to="3" className=""></li>
          <li data-target="#myCarousel" data-slide-to="4" className=""></li> */}
        </ol>
        <div className="carousel-inner" role="listbox">
          {bannerList.map((items, index) => {
            console.log({ name: items.productId.name });
            var arrayTitle = items.productId.name.split(" ");
            var title1 = "";
            var title2 = "";
            if (arrayTitle.length > 1) {
              title1 = arrayTitle.slice(0, arrayTitle.length - 1).join(" ");
              title2 = arrayTitle
                .slice(arrayTitle.length - 1, arrayTitle.length)
                .join(" ");
            } else {
              title1 = items.productId.name;
            }
            return (
              <div
                className={index === 0 ? "item active" : "item"}
                style={{
                  background: `linear-gradient(rgba(23, 22, 23, 0.2), rgba(23, 22, 23, 0.5)), url(${apiURL}${items.imageUrl}) no-repeat`,
                  backgroundSize: "cover",
                }}
              >
                <div className="container">
                  <div className="carousel-caption">
                    <h3>
                      {title1} {title2 !== "" && <span>{title2}</span>}
                    </h3>
                    <p>{items.subTitle}</p>
                    <Link
                      className="hvr-outline-out button2"
                      to={`products/${items.productId.sUrl}`}
                    >
                      View Now
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
          {/* <div className="item active">
            <div className="container">
              <div className="carousel-caption">
                <h3>
                  The Biggest <span>Sale</span>
                </h3>
                <p>Special for today</p>
                <a className="hvr-outline-out button2" href="mens.html">
                  Shop Now
                </a>
              </div>
            </div>
          </div>
          <div className="item item2">
            <div className="container">
              <div className="carousel-caption">
                <h3>
                  Summer <span>Collection</span>
                </h3>
                <p>New Arrivals On Sale</p>
                <a className="hvr-outline-out button2" href="mens.html">
                  Shop Now
                </a>
              </div>
            </div>
          </div>
          <div className="item item3">
            <div className="container">
              <div className="carousel-caption">
                <h3>
                  The Biggest <span>Sale</span>
                </h3>
                <p>Special for today</p>
                <a className="hvr-outline-out button2" href="mens.html">
                  Shop Now
                </a>
              </div>
            </div>
          </div>
          <div className="item item4">
            <div className="container">
              <div className="carousel-caption">
                <h3>
                  Summer <span>Collection</span>
                </h3>
                <p>New Arrivals On Sale</p>
                <a className="hvr-outline-out button2" href="mens.html">
                  Shop Now
                </a>
              </div>
            </div>
          </div> */}
        </div>
        <a
          className="left carousel-control"
          href="#myCarousel"
          role="button"
          data-slide="prev"
        >
          <span
            className="glyphicon glyphicon-chevron-left"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="right carousel-control"
          href="#myCarousel"
          role="button"
          data-slide="next"
        >
          <span
            className="glyphicon glyphicon-chevron-right"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
        {/* <!-- The Modal --> */}
      </div>

      {/* <div className="banner_bottom_agile_info">
        <div className="container">
          <div className="banner_bottom_agile_info_inner_w3ls">
            <div className="col-md-6 wthree_banner_bottom_grid_three_left1 grid">
              <figure className="effect-roxy">
                <img
                  src="images/bottom1.jpg"
                  alt=" "
                  className="img-responsive"
                />
                <figcaption>
                  <h3>
                    <span>F</span>all Ahead
                  </h3>
                  <p>New Arrivals</p>
                </figcaption>
              </figure>
            </div>
            <div className="col-md-6 wthree_banner_bottom_grid_three_left1 grid">
              <figure className="effect-roxy">
                <img
                  src="images/bottom2.jpg"
                  alt=" "
                  className="img-responsive"
                />
                <figcaption>
                  <h3>
                    <span>F</span>all Ahead
                  </h3>
                  <p>New Arrivals</p>
                </figcaption>
              </figure>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
     */}
    </Fragment>
  );
};

export default Banner;
