// src/reusable/image-gallery.component.js
import React, { Fragment } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Header from "../../components/common/header";
import HeaderBot from "../../components/common/headerBot";
import BannerTop from "../../components/common/navbarTop";
import Banner from "../../components/common/banner";
import SamiBanner from "../../components/common/samiBanner";
import CouponsGrids from "../../components/common/couponsGrids";
import EnquiryFormModal from "../../components/common/enquiryFormModal";
import ProductLists from "../../components/common/productLists";
import Loader from "../../components/common/loader";
import EnquiryForm from "../../components/common/enquiryForm";
import HomeService from "../../services/homeService";
import ProductService from "../../services/productService";
import { apiURL } from "../../utlis/config";
import store from "store";
import $ from "jquery";

class ImageGallaryComponent extends React.Component {
  state = {
    dataInfo: {},
    brandList: [],
    categoryList: [],
    reload: true,
  };
  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    console.log(this.props, "props---@12-", { params });
    if (params.searchValue) {
      this.setState({ searchValue: params.searchValue }, function () {
        this.callApiHeader();
        this.callApiInfo();
      });
    }
  }

  componentDidUpdate() {
    console.log(this.props, "props----@21---");
    const { match } = this.props;
    const { params } = match;
    const { searchValue } = params;
    if (!searchValue && store.get("prvUrl")) {
      this.props.history.push(store.get("prvUrl"));
    }
    // store.set("prvUrl", this.props.location.pathname);
    if (this.state.searchValue !== searchValue) {
      this.setState(
        {
          loader: true,
          searchValue: searchValue,
        },
        function () {
          $.ajaxQ.abortAll();
          this.callApiHeader();
          this.callApiInfo();
        }
      );
    }
  }

  callApiHeader = () => {
    HomeService.fetchBrandCategoryInfo().then((response) => {
      if (!response.error) {
        var header = store.get("header");
        this.updateEvent(header);
      }
    });
  };
  updateEvent = (headerInfo) => {
    var event = new CustomEvent("headerInfo", {
      detail: { headerInfo: headerInfo },
    });
    document.dispatchEvent(event);
  };
  callApiInfo = () => {
    const { searchValue } = this.state;
    ProductService.searchProduct(searchValue).then((response) => {
      if (!response.error) {
        this.setState({
          dataInfo: response.data,
        });
      } else {
        this.props.history.push("/product");
      }
    });
    this.setState({ loader: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const formData = {};
    formData["name"] = data.get("name");
    formData["email"] = data.get("email");
    formData["contactNumber"] = data.get("contactNumber");
    formData["countryCode"] = data.get("countryCode");
    formData["country"] = data.get("country");
    formData["quantity"] = data.get("quantity");
    formData["productId"] = data.get("productId");
    this.setState({ loader: true }, function () {
      ProductService.sendEnqury(formData).then((response) => {
        this.setState({ loader: false, showEnquiryFormModal: false });
        if (!response.error) {
          this.props.history.push("/thanks");
        } else {
        }
      });
      this.setState({ loader: false });
    });
  };

  render() {
    const {
      dataInfo,
      categoryInfo,
      brandInfo,
      filter,
      showEnquiryFormModal,
      productInfo,
      loader,
    } = this.state;

    const propsProductList = {
      categoryInfo,
      dataInfo,
      rediractUrl: (url) => {
        this.props.history.push(url);
        // window.location.reload();
      },
      onClickEnquiry: (showEnquiryFormModal, productInfo) => {
        this.setState({ showEnquiryFormModal, productInfo });
      },
    };
    const propsEnquiryFormModal = {
      modalShow: showEnquiryFormModal,
      productInfo,
      onClickToggle: () => {
        this.setState({ showEnquiryFormModal: false });
      },
      onClickConfirm: this.handleSubmit,
    };
    return (
      <Fragment>
        <SamiBanner title={"search"} subTitle={"search"} />
        <>
          <div class="banner-bootom-w3-agileits" style={{ padding: "25px 4%" }}>
            <div class="row">
              {loader && <Loader />}
              <div class={filter ? "col-md-9 p-5" : "col-md-12 p-5"}>
                {dataInfo && (
                  <div class={"single-right-left simpleCart_shelfItem p-15"}>
                    <ProductLists {...propsProductList} />
                  </div>
                )}

                {!loader && dataInfo && dataInfo.length == 0 && (
                  <div className="text-center">
                    <img
                      src={require("../../assets/images/noProduct.png")}
                      alt=" "
                    />
                  </div>
                )}
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </>
        {/* <CouponsGrids /> */}

        {<EnquiryFormModal {...propsEnquiryFormModal} />}
      </Fragment>
    );
  }
}

export default ImageGallaryComponent;
