import React, { Fragment } from "react";
import Header from "../../components/common/header";
import HeaderBot from "../../components/common/headerBot";
import Loader from "../../components/common/loader";
import Banner from "../../components/common/banner";
import Footer from "../../components/common/footer";
import CouponsGrids from "../../components/common/couponsGrids";
import WeOffer from "../../components/common/weOffer";
import ProductLists from "../../components/common/productLists";
import SlideView from "../../components/common/slideView";
import EnquiryFormModal from "../../components/common/enquiryFormModal";
import HomeService from "../../services/homeService";
import ProductService from "../../services/productService";
import store from "store";
import $ from "jquery";
import { Helmet } from "react-helmet";

class Home extends React.Component {
  state = {
    bannerList: [],
    brandList: [],
    categoryList: [],
    productList: [],
  };
  componentDidMount() {
    store.set("prvUrl", this.props.location.pathname);
    this.callApiInfo();
  }
  componentDidUpdate() {
    store.set("prvUrl", this.props.location.pathname);
  }
  callApiInfo = () => {
    HomeService.fetchBrandCategoryInfo().then((response) => {
      if (!response.error) {
        var header = store.get("header");
        this.updateEvent(header);
        this.setState({
          bannerList: header.bannerList,
          brandList: header.brandList,
          categoryList: header.categoryList,
          productList: header.productList,
        });
      } else {
      }
    });
    this.setState({ loader: false });
  };
  updateEvent = (headerInfo) => {
    var event = new CustomEvent("headerInfo", {
      detail: { headerInfo: headerInfo },
    });
    document.dispatchEvent(event);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const formData = {};
    formData["name"] = data.get("name");
    formData["email"] = data.get("email");
    formData["contactNumber"] = data.get("contactNumber");
    formData["countryCode"] = data.get("countryCode");
    formData["country"] = data.get("country");
    formData["quantity"] = data.get("quantity");
    formData["productId"] = data.get("productId");
    this.setState({ loader: true }, function () {
      ProductService.sendEnqury(formData).then((response) => {
        this.setState({ loader: false, showEnquiryFormModal: false });
        if (!response.error) {
          this.props.history.push("/thanks");
        } else {
        }
      });
      this.setState({ loader: false });
    });
  };
  render() {
    const {
      bannerList,
      brandList,
      categoryList,
      productList,
      showEnquiryFormModal,
      productInfo,
      loader,
    } = this.state;
    const propsSlideView = {
      brandList: brandList,
      categoryList: categoryList,
      productList: productList,
      rediractUrl: (url) => {
        this.props.history.push(url);
        // window.location.reload();
      },
      onClickEnquiry: (showEnquiryFormModal, productInfo) => {
        this.setState({ showEnquiryFormModal, productInfo });
      },
    };
    const propsEnquiryFormModal = {
      modalShow: showEnquiryFormModal,
      productInfo,
      onClickToggle: () => {
        this.setState({ showEnquiryFormModal: false });
      },
      onClickConfirm: this.handleSubmit,
    };
    return (
      <Fragment>
        {/* <!-- banner --> */}
        <Banner bannerList={bannerList} />
        {/* <!-- //banner --> */}

        {loader && <Loader />}
        {/* <!-- /new_arrivals -->  */}
        <SlideView {...propsSlideView} />
        {/* <ProductLists /> */}

        {/* <!-- //new_arrivals -->  */}
        {/* <!-- /we-offer --> */}
        {/* <WeOffer /> */}
        {/* <!-- //we-offer --> */}
        {/* <!--/couponsGrids--> */}
        {/* <CouponsGrids /> */}
        {/* <!--couponsGrids--> */}
        {<EnquiryFormModal {...propsEnquiryFormModal} />}
      </Fragment>
    );
  }
}

export default Home;
