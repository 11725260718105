import React, { useState, useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./icon.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
// import indexStore from "./store/index";

// ** Import custom components for redux**
// import { Provider } from "react-redux";
// import store from "./store/index";
import App from "./components/app";
// import Home from "./pages/home";
import Routs from "./routes";
import "./index.css";
// import "./assets/css/bootstrap.css";
// import "./assets/css/easy-responsive-tabs.css";
// import "./assets/css/flexslider.css";
// import "./assets/css/font-awesome.css";
// import "./assets/css/jquery-ui.css";
// import "./assets/css/style.css";
// import "./assets/css/team.css";

//firebase Auth
function Root() {
  const user = true;
  console.log({ env: process.env });
  return (
    <div className="App">
      {/* <Provider> */}
      <BrowserRouter basename={`/`}>
        <ScrollContext>
          <Switch>
            {/* <Route path={`/`} component={Home} /> */}
            <Fragment>
              <App>
                <Route
                  exact
                  path={`/home`}
                  render={() => {
                    return <Redirect to={`/`} />;
                  }}
                />
                <Route path={`/`} component={Routs} />
              </App>
            </Fragment>
          </Switch>
        </ScrollContext>
      </BrowserRouter>
      {/* </Provider> */}
    </div>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
