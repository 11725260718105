module.exports = {
  siteName: "LIC Admin",
  copyright: "Copyright © All rights reserved |  ",
  logoPath: "/image/logo.png",
  // apiPrefix: `${process.env.NODE_ENV == 'production' ? "http://172.105.35.119" //process.env.API_URL : "http://172.105.35.119:8080"}`,
  // socketURL: process.env.NODE_ENV == 'production' ? process.env.SOCKET_URL : "http://172.105.35.119:8080",
  // reportDownloadUrl: `${process.env.NODE_ENV == 'production' ? "http://172.105.35.119" //process.env.API_URL : "http://172.105.35.119:8080"}/reports`,
  // uploadMediaUrl: `${process.env.NODE_ENV == 'production' ? "http://172.105.35.119" //process.env.API_URL : "http://172.105.35.119:8080"}/media`,
  // apiURL: `${process.env.NODE_ENV == 'production' ? "http://172.105.35.119" //process.env.API_URL : "http://172.105.35.119:8080"}/api`,
  apiPrefix: `${
    process.env.NODE_ENV == "production"
      ? process.env.API_URL || "https://arabtechstore.com"
      : "http://localhost:8080"
  }:8080`,
  socketURL: `${
    process.env.NODE_ENV == "production"
      ? process.env.API_URL || "https://arabtechstore.com"
      : "http://localhost:8080"
  }:8080`,
  reportDownloadUrl: `${
    process.env.NODE_ENV == "production"
      ? process.env.API_URL || "https://arabtechstore.com"
      : "http://localhost:8080"
  }/reports`,
  uploadMediaUrl: `${
    process.env.NODE_ENV == "production"
      ? process.env.API_URL || "https://arabtechstore.com"
      : "http://localhost:8080"
  }/media`,
  apiURL: `${
    process.env.NODE_ENV == "production"
      ? process.env.API_URL || "https://arabtechstore.com"
      : "http://localhost:8080"
  }/api`,
  importPolicyPlanUrl: `${
    process.env.NODE_ENV == "production"
      ? process.env.API_URL || "https://arabtechstore.com"
      : "http://localhost:8080"
  }/api/policy/plan/import`,
};
