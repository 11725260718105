import React, { Fragment } from "react";
import store from "store";
import { apiURL, apiPrefix } from "../../utlis/config";
import { CountryList } from "../../utlis/constant";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const EnquiryForm = (propsInfo) => {
  console.log({ propsInfo });
  const {
    productInfo,
    modalFormShow,
    onClickToggle,
    onClickConfirm,
  } = propsInfo;
  // const
  return (
    <Fragment>
      <div class="contact-form">
        <h4 class="black-form-head">
          Enquiry <span>Form</span>
        </h4>
        <form onSubmit={onClickConfirm} method="post">
          <div className="styled-input agile-styled-input-top">
            <input
              type="text"
              name="name"
              // placeholder="Enter name..."
              required=""
            />
            <label>Name *</label>
            <span></span>
          </div>
          <div className="styled-input">
            <input
              type="email"
              name="email"
              // placeholder="Enter email..."
              required=""
            />
            <label>Email *</label>
            <span></span>
          </div>
          <div className="styled-input">
            <input
              type="number"
              name="contactNumber"
              // placeholder="Enter contact..."
              required=""
            />
            <label>Contact * </label>
            <span></span>
          </div>
          <div className="styled-input">
            <select type="text" name="country" required="">
              <option value="all"> </option>
              {/* <option value="all">Select country...</option> */}
              {/* <option value="india">India</option> */}
              {CountryList.map((data) => {
                return <option value={data.name}>{data.name}</option>;
              })}
            </select>
            <label> Country *</label>
            <span></span>
          </div>
          <div className="styled-input">
            <input
              type="number"
              name="quantity"
              // placeholder="Enter quantity..."
              required=""
            />
            <label>Quantity *</label>
            <span></span>
          </div>

          <input type="text" name="productId" value={productInfo._id} hidden />
          <input type="submit" placeholder="Submit" />
        </form>
      </div>
    </Fragment>
  );
};

export default EnquiryForm;
