// src/reusable/image-gallery.component.js
import React, { Fragment } from "react";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Header from "../../components/common/header";
import HeaderBot from "../../components/common/headerBot";
import BannerTop from "../../components/common/navbarTop";
import Banner from "../../components/common/banner";
import SamiBanner from "../../components/common/samiBanner";
import CouponsGrids from "../../components/common/couponsGrids";
import EnquiryFormModal from "../../components/common/enquiryFormModal";
import ProductLists from "../../components/common/productLists";
import Loader from "../../components/common/loader";
import EnquiryForm from "../../components/common/enquiryForm";
import HomeService from "../../services/homeService";
import ProductService from "../../services/productService";
import { apiURL } from "../../utlis/config";
import store from "store";
import $ from "jquery";

class ImageGallaryComponent extends React.Component {
  state = {
    dataInfo: {},
    brandList: [],
    categoryList: [],
    reload: true,
  };
  componentDidMount() {
    // window.location.reload();
    $("body").removeClass("modal-open");
    $("div").removeClass("modal-backdrop");
    $("div").removeClass("fade");
    this.callApiHeader();
  }
  callApiHeader = () => {
    HomeService.fetchBrandCategoryInfo().then((response) => {
      if (!response.error) {
        var header = store.get("header");
        this.updateEvent(header);
      }
    });
  };
  updateEvent = (headerInfo) => {
    var event = new CustomEvent("headerInfo", {
      detail: { headerInfo: headerInfo },
    });
    document.dispatchEvent(event);
  };
  render() {
    return (
      <Fragment>
        <SamiBanner title={"Thanks"} subTitle={"Thanks"} />
        <>
          <div class="banner-bootom-w3-agileits" style={{ padding: "25px 4%" }}>
            <div class="row m-t-25 m-b-25">
              <div class="col-md-12 p-5 m-t-25 m-b-25 text-center">
                <p style={{ fontSize: 18 }}>
                  Thank You for your inquiry with us. Your request has been
                  submitted successfully!
                </p>
                <p style={{ fontSize: 18 }}>
                  {" "}
                  Our representative will get in touch with you shortly.
                </p>
              </div>
              <div class="col-md-12 p-5 m-t-25 m-b-25 text-center">
                <button
                  onClick={() => {
                    this.props.history.push(store.get("prvUrl"));
                  }}
                  className="back-btn"
                >
                  Back
                </button>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </>
        {/* <CouponsGrids /> */}
      </Fragment>
    );
  }
}

export default ImageGallaryComponent;
