import React, { Fragment } from "react";
import Loader from "react-loader-spinner";

const LoaderView = (loaderInfo) => {
  return (
    <Fragment>
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "46%",
          zIndex: "+999",
        }}
      >
        <div style={{ position: "absolute" }}>
          <Loader type="Rings" color="#002b7a" height={100} width={100} />
        </div>
      </div>
    </Fragment>
  );
};

export default LoaderView;
