import React, { Fragment } from "react";
import store from "store";
import { useLocation, withRouter } from "react-router";
import { Link } from "react-router-dom";
var headerInfo = store.get("header");
class HeaderBot extends React.Component {
  state = { headerInfo: {} };
  componentDidMount() {
    console.log(this.props, "header props");
    this.setState({ headerInfo: store.get("header") });
    var self = this;
    document.addEventListener("headerInfo", function (e) {
      // Prints "Example of an event"
      headerInfo = e.detail.headerInfo;
      console.log({ headerInfo }, "headerInfo");
      // setheaderInfo(headerInfo);
      self.setState({ headerInfo });
    });
  }
  render() {
    const { headerInfo } = this.state;
    console.log({ headerInfo }, "headerInfo---@@--");
    // const { brandList, categoryList } = headerInfo;
    return (
      <Fragment>
        <div className="header-bot">
          <div className="header-bot_inner_wthreeinfo_header_mid">
            <div className="col-md-4 header-middle">
              <form>
                <input
                  type="search"
                  name="search"
                  placeholder="Search here..."
                  required=""
                  onChange={(e) => {
                    // console.log(e.target.value, "------");
                    this.props.history.push("/search/" + e.target.value);
                  }}
                />
                <input type="button" value=" " />
                <div className="clearfix"></div>
              </form>
            </div>
            {/* <!-- header-bot --> */}
            <div className="col-md-4 logo_agile">
              <h1>
                <Link to="/" style={{ marginTop: 5 }}>
                  {/* <span>E</span>lite Shoppy */}
                  <i className="fa fa-ats-logo-icon"></i>
                  {/* <i
                    className="fa fa-shopping-bag top_logo_agile_bag"
                    aria-hidden="true"
                  ></i> */}
                </Link>
              </h1>
            </div>
            {/* <!-- header-bot --> */}
            <div className="col-md-4 agileits-social top_content">
              <ul className="social-nav model-3d-0 footer-social w3_agile_social">
                <li className="share">Share On : </li>
                <li>
                  <a
                    href="https://www.facebook.com/Audio-Rental-Audiobot-Store-Trading-100857949429326/?ti=as"
                    className="facebook"
                    target="_blank"
                  >
                    <div className="front">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </div>
                    <div className="back">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </div>
                  </a>
                </li>
                {/* <li>
                  <a href="#" className="twitter" target="_blank">
                    <div className="front">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </div>
                    <div className="back">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#" className="instagram" target="_blank">
                    <div className="front">
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </div>
                    <div className="back">
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#" className="pinterest" target="_blank">
                    <div className="front">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </div>
                    <div className="back">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </div>
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(HeaderBot);
