import React, { Fragment } from "react";
import store from "store";
import { apiURL, apiPrefix } from "../../utlis/config";

import { Link } from "react-router-dom";
const ProductLists = (propdInfo) => {
  console.log({ propdInfo });
  const { dataInfo, rediractUrl, onClickEnquiry } = propdInfo;
  console.log({ dataInfo });
  // const
  return (
    <Fragment>
      <div className="">
        {/* <div className="container"> */}
        {/* <h3 className="wthree_text_info">
            New <span>Arrivals</span>
          </h3> */}
        <div>
          <div className="resp-tabs-container">
            {/* <!--/tab_one--> */}
            <div className="tab1">
              {dataInfo &&
                dataInfo.length > 0 &&
                dataInfo.map((post) => {
                  return (
                    <div className="col-md-3 product-men">
                      <div className="men-pro-item simpleCart_shelfItem">
                        <div className="men-thumb-item">
                          <img
                            src={post.pictursList[0]["url"]}
                            alt=""
                            className="pro-image-front"
                          />
                          <img
                            src={post.pictursList[0]["url"]}
                            alt=""
                            className="pro-image-back"
                          />
                          <div className="men-cart-pro">
                            <div className="inner-men-cart-pro">
                              <a
                                onClick={() => {
                                  rediractUrl(`/product/${post.fullUrl}`);
                                }}
                                href="#"
                                className="link-product-add-cart"
                              >
                                Quick View
                              </a>
                            </div>
                          </div>
                          {/* <span className="product-new-top">New</span> */}
                        </div>
                        <div className="item-info-product ">
                          <h4>
                            <a
                              onClick={() => {
                                rediractUrl(`/product/${post.fullUrl}`);
                              }}
                              href="#"
                            >
                              {post.name}
                            </a>
                          </h4>
                          <div className="info-product-price">
                            {/* <span className="item_price">$45.99</span>
                          <del>$69.71</del> */}
                          </div>
                          <div className="snipcart-details top_brand_home_details item_add single-item hvr-outline-out button2">
                            {/* <input
                              type="submit"
                              name="submit"
                              placeholder="Send Enquiry"
                              className="button"
                            /> */}
                            <input
                              data-toggle="modal"
                              data-target="#enquiryModal"
                              onClick={() => {
                                onClickEnquiry(true, post);
                              }}
                              type="button"
                              name="Send Enquiry"
                              placeholder="Send Enquiry"
                              class="button"
                              value="Send Enquiry"
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </Fragment>
  );
};

export default ProductLists;
