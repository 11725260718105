import $ from "jquery";
import { toast } from "react-toastify";
import { apiURL } from "../utlis/config";
import store from "store";
// const apiURL = "http://172.105.35.119:8080/api";
var Actions = {
  searchProduct: (searchText) => {
    console.log(
      {
        searchText,
      },
      "products=-----@"
    );
    //console.log("startDate  ==", startDate !== "" ? searchText : undefined)

    toast.dismiss();
    return new Promise((resolve, reject) => {
      var url = apiURL + "/public/search";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "GET",
        headers: {
          Authorization: "Bearer " + store.get("authToken"),
          // fngprt: m.cookie.get("fngprt")
        },
        data: {
          searchText: searchText !== "" ? searchText : undefined,
        },
        success: function (response) {
          if (!response.error) {
            toast.success(
              response.memberMessage,
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          } else {
            toast.error(
              response.memberMessage,
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          }
          resolve(response);
        },
        error: function (error) {
          if (error.status == 401) {
            toast.error(
              "Your session has been expired. Please relogin.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            toast.error(
              "Some error has occurred.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          }
        },
      };
      $.ajax(settings).done();
    });
  },
  fetchProductsInfo: (
    productUrl,
    categoryUrl,
    brandUrl,
    filterCategoryList,
    filterBrandList,
    filterSubCategoryList,
    page,
    pageSize,
    searchText
  ) => {
    console.log(
      {
        productUrl,
        categoryUrl,
        brandUrl,
        filterCategoryList,
        filterBrandList,
        filterSubCategoryList,
        page,
        pageSize,
        searchText,
      },
      "products=-----@"
    );
    //console.log("startDate  ==", startDate !== "" ? searchText : undefined)

    toast.dismiss();
    return new Promise((resolve, reject) => {
      var url = apiURL + "/public/products";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "GET",
        headers: {
          Authorization: "Bearer " + store.get("authToken"),
          // fngprt: m.cookie.get("fngprt")
        },
        data: {
          sUrl: productUrl,
          categoryUrl,
          brandUrl,
          filterCategoryList,
          filterBrandList,
          filterSubCategoryList,
          limit: pageSize,
          page: page,
          searchText: searchText !== "" ? searchText : undefined,
        },
        success: function (response) {
          if (!response.error) {
            toast.success(
              response.memberMessage,
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          } else {
            toast.error(
              response.memberMessage,
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          }
          resolve(response);
        },
        error: function (error) {
          if (error.status == 401) {
            toast.error(
              "Your session has been expired. Please relogin.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            toast.error(
              "Some error has occurred.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          }
        },
      };
      $.ajax(settings).done();
    });
  },
  deactivateProductId: (productId) => {
    //deactivateMemberId
    toast.dismiss();
    return new Promise((resolve, reject) => {
      var url = apiURL + "/products";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + store.get("authToken"),
          // fngprt: m.cookie.get("fngprt")
        },
        data: { productId },

        success: function (response) {
          if (!response.error) {
            toast.success(
              response.memberMessage,
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          } else {
            toast.error(
              response.memberMessage,
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          }
          resolve(response);
        },
        error: function (error) {
          if (error.status == 401) {
            toast.error(
              "Your session has been expired. Please relogin.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            toast.error(
              "Some error has occurred.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          }
        },
      };

      $.ajax(settings).done();
    });
  },
  fetchProductList: () => {
    //console.log("startDate  ==", startDate !== "" ? searchText : undefined)

    toast.dismiss();
    return new Promise((resolve, reject) => {
      var url = apiURL + "/product/list";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "GET",
        headers: {
          Authorization: "Bearer " + store.get("authToken"),
          // fngprt: m.cookie.get("fngprt")
        },
        success: function (response) {
          resolve(response);
        },
        error: function (error) {
          if (error.status == 401) {
            toast.error(
              "Your session has been expired. Please relogin.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            toast.error(
              "Some error has occurred.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          }
        },
      };
      $.ajax(settings).done();
    });
  },

  sendEnqury: (info) => {
    toast.dismiss();
    return new Promise((resolve, reject) => {
      var url = apiURL + "/client";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "POST",
        headers: {
          Authorization: "Bearer " + store.get("authToken"),
          // fngprt: m.cookie.get("fngprt")
        },
        data: info,
        success: function (response) {
          resolve(response);
        },
        error: function (error) {
          if (error.status == 401) {
            toast.error(
              "Your session has been expired. Please relogin.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            toast.error(
              "Some error has occurred.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          }
        },
      };
      $.ajax(settings).done();
    });
  },
  contactUs: (info) => {
    toast.dismiss();
    return new Promise((resolve, reject) => {
      var url = apiURL + "/contact";
      var settings = {
        async: true,
        crossDomain: true,
        url: url,
        method: "POST",
        headers: {
          Authorization: "Bearer " + store.get("authToken"),
          // fngprt: m.cookie.get("fngprt")
        },
        data: info,
        success: function (response) {
          resolve(response);
        },
        error: function (error) {
          if (error.status == 401) {
            toast.error(
              "Your session has been expired. Please relogin.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
            localStorage.clear();
            window.location.reload();
          } else if (error.statusText === "abort") {
          } else {
            toast.error(
              "Some error has occurred.",
              {
                position: toast.POSITION.TOP_CENTER,
              },
              { autoClose: 500 }
            );
          }
        },
      };
      $.ajax(settings).done();
    });
  },
};

$.ajaxQ = (function () {
  //console.log("--------------------------");
  var id = 0,
    Q = {};
  $(document).ajaxSend(function (e, jqx) {
    jqx._id = ++id;
    Q[jqx._id] = jqx;
  });
  $(document).ajaxComplete(function (e, jqx) {
    delete Q[jqx._id];
  });
  return {
    abortAll: function () {
      var r = [];
      $.each(Q, function (i, jqx) {
        r.push(jqx._id);
        jqx.abort();
      });
      return r;
    },
  };
})();
export default Actions;
