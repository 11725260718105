import React, { Fragment } from "react";
import Header from "../../components/common/header";
import HeaderBot from "../../components/common/headerBot";
import Loader from "../../components/common/loader";
import SamiBanner from "../../components/common/samiBanner";
import SideFilter from "../../components/common/sideFilter";
import CouponsGrids from "../../components/common/couponsGrids";
import WeOffer from "../../components/common/weOffer";
import ProductLists from "../../components/common/productLists";
import PageHeadAgileInfo from "../../components/common/pageHeadAgileInfo";
import EnquiryForm from "../../components/common/enquiryForm";
import HomeService from "../../services/homeService";
import EnquiryFormModal from "../../components/common/enquiryFormModal";
import ProductService from "../../services/productService";
import { apiURL } from "../../utlis/config";
import store from "store";
import $ from "jquery";
import { Helmet } from "react-helmet";

class Home extends React.Component {
  state = {
    dataInfo: {},
    brandList: [],
    categoryInfo: {},
    brandInfo: {},
    filter: false,
    loader: false,
    headerInfo: {},
  };
  componentDidMount() {
    store.set("prvUrl", this.props.location.pathname);
    this.setState({ headerInfo: store.get("header") });
    var self = this;
    document.addEventListener("headerInfo", function (e) {
      // Prints "Example of an event"
      var headerInfo = e.detail.headerInfo;
      self.setState({ headerInfo });
    });
    const { match } = this.props;
    const { params } = match;
    console.log(this.props, "props---brandUrl-", { params });
    if (params.brandUrl) {
      this.setState({ brandUrl: params.brandUrl }, function () {
        this.callApiHeader();
        this.callApiInfo();
      });
    }
  }
  componentDidUpdate() {
    store.set("prvUrl", this.props.location.pathname);
    const { brandUrl } = this.state;
    const { match } = this.props;
    const { params } = match;
    console.log(this.props, "props---brandUrl-", { params });
    if (params.brandUrl !== brandUrl) {
      this.setState({ brandUrl: params.brandUrl }, function () {
        this.callApiHeader();
        this.callApiInfo();
      });
    }
  }

  callApiHeader = () => {
    HomeService.fetchBrandCategoryInfo().then((response) => {
      if (!response.error) {
        var header = store.get("header");
        this.updateEvent(header);
      }
    });
  };
  updateEvent = (headerInfo) => {
    var event = new CustomEvent("headerInfo", {
      detail: { headerInfo: headerInfo },
    });
    document.dispatchEvent(event);
  };

  callApiInfo = () => {
    this.setState(
      { loader: true, dataInfo: [], categoryInfo: {}, totalRecords: 0 },
      function () {
        const { brandUrl, filterCategories } = this.state;
        ProductService.fetchProductsInfo(
          undefined,
          undefined,
          brandUrl,
          filterCategories
        ).then((response) => {
          if (!response.error) {
            this.setState({
              dataInfo: response.data,
              categoryInfo: response.categoryInfo,
              brandInfo: response.brandInfo,
              totalRecords: response.totalRecords,
            });
          } else {
            // this.props.history.push("/product");
          }
        });
        this.setState({ loader: false });
      }
    );
  };
  filterToggel = () => {
    const { filter } = this.state;
    this.setState({ filter: !filter });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const formData = {};
    formData["name"] = data.get("name");
    formData["email"] = data.get("email");
    formData["contactNumber"] = data.get("contactNumber");
    formData["countryCode"] = data.get("countryCode");
    formData["country"] = data.get("country");
    formData["quantity"] = data.get("quantity");
    formData["productId"] = data.get("productId");
    this.setState({ loader: true }, function () {
      ProductService.sendEnqury(formData).then((response) => {
        this.setState({ loader: false, showEnquiryFormModal: false });
        if (!response.error) {
          this.props.history.push("/thanks");
        } else {
        }
      });
      this.setState({ loader: false });
    });
  };

  render() {
    const {
      dataInfo,
      categoryInfo,
      brandInfo,
      filter,
      showEnquiryFormModal,
      productInfo,
      headerInfo,
      loader,
    } = this.state;

    const { brandList, categoryList } = headerInfo;
    const propsProductList = {
      categoryInfo,
      dataInfo,
      rediractUrl: (url) => {
        this.props.history.push(url);
        // window.location.reload();
      },
      onClickEnquiry: (showEnquiryFormModal, productInfo) => {
        this.setState({ showEnquiryFormModal, productInfo });
      },
    };
    const propsEnquiryFormModal = {
      modalShow: showEnquiryFormModal,
      productInfo,
      onClickToggle: () => {
        this.setState({ showEnquiryFormModal: false });
      },
      onClickConfirm: this.handleSubmit,
    };
    const propsSideFilter = {
      categoryList: categoryList,
      isBrand: true,
      onCategoryChange: (value) => {
        console.log({ value });
        this.setState(
          {
            filterCategories: value,
          },
          function () {
            $.ajaxQ.abortAll();
            this.callApiInfo();
          }
        );
      },
    };
    return (
      <Fragment>
        <SamiBanner title={brandInfo.name} subTitle={"Brand"} />
        <Helmet>
          <meta charSet="utf-8" />
          <title>{brandInfo.sTitle}</title>
          <link
            rel="canonical"
            href={`${"https://arabtechstore.com/"}product/${brandInfo.fullUrl}`}
          />
          <meta name="keyword" content={brandInfo.sKeyword} />
          <meta name="description" content={brandInfo.shortDescription} />
        </Helmet>
        <>
          <div class="banner-bootom-w3-agileits" style={{ padding: "25px 4%" }}>
            <div class="row">
              <div class="col-md-3   p-5">
                <div
                  class=" single-right-left men-pro-item p-15"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.filterToggel()}
                >
                  <h3>Filter</h3>
                  {/* right */}
                  <i
                    class={
                      filter
                        ? "fa fa-chevron-down toggle-data"
                        : "fa fa-chevron-right toggle-data"
                    }
                    style={{
                      position: "absolute",
                      right: "14px",
                      top: "18px",
                    }}
                  ></i>
                </div>
              </div>
              <div class="col-md-9 p-5">
                <div
                  class=" single-right-left simpleCart_shelfItem men-pro-item p-15 "
                  style={{ fontWeight: 600, fontSize: "1.1em" }}
                >
                  {" "}
                  <ul
                    style={{
                      textDecoration: "none",
                      listStyleType: "none",
                      display: "flex",
                    }}
                  >
                    <li style={{ color: "#002b7a" }}>Home&nbsp;/</li>
                    <li style={{ color: "#fc636b" }}> &nbsp;Brand's &nbsp;</li>
                    <li style={{ color: "#002b7a" }}>
                      /&nbsp;{brandInfo.name}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>

            <div class="row">
              {loader && <Loader />}
              {filter && (
                <div class="col-md-3 p-5">
                  <div class="single-right-left simpleCart_shelfItem men-pro-item  m-t-30">
                    <SideFilter {...propsSideFilter} />
                  </div>
                </div>
              )}
              <div class={filter ? "col-md-9 p-5" : "col-md-12 p-5"}>
                {dataInfo && (
                  <div class={"single-right-left simpleCart_shelfItem p-15"}>
                    <ProductLists {...propsProductList} />
                  </div>
                )}

                {!loader && dataInfo && dataInfo.length == 0 && (
                  <div className="text-center">
                    <img
                      src={require("../../assets/images/noProduct.png")}
                      alt=" "
                    />
                  </div>
                )}
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </>
        {/* <CouponsGrids /> */}

        {<EnquiryFormModal {...propsEnquiryFormModal} />}
      </Fragment>
    );
  }
}

export default Home;
