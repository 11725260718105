import React, { Fragment } from "react";
import Header from "../../components/common/header";
import HeaderBot from "../../components/common/headerBot";
import Loader from "../../components/common/loader";
import SamiBanner from "../../components/common/samiBanner";
import SideFilter from "../../components/common/sideFilter";
import CouponsGrids from "../../components/common/couponsGrids";
import HomeService from "../../services/homeService";
import ProductService from "../../services/productService";
import { apiURL } from "../../utlis/config";
import store from "store";
import $ from "jquery";
import { Helmet } from "react-helmet";

class ContactUs extends React.Component {
  state = { loader: false };
  componentDidMount() {
    store.set("prvUrl", this.props.location.pathname);
    this.callApiHeader();
  }
  componentDidUpdate() {
    store.set("prvUrl", this.props.location.pathname);
  }
  callApiHeader = () => {
    HomeService.fetchBrandCategoryInfo().then((response) => {
      if (!response.error) {
        var header = store.get("header");
        this.updateEvent(header);
      }
    });
  };
  updateEvent = (headerInfo) => {
    var event = new CustomEvent("headerInfo", {
      detail: { headerInfo: headerInfo },
    });
    document.dispatchEvent(event);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const formData = {};
    formData["name"] = data.get("name");
    formData["email"] = data.get("email");
    formData["subject"] = data.get("subject");
    formData["message"] = data.get("message");
    this.setState({ loader: true }, function () {
      ProductService.contactUs(formData).then((response) => {
        this.setState({ loader: false, showEnquiryFormModal: false });
        if (!response.error) {
          this.props.history.push("/thanks");
        } else {
        }
      });
      this.setState({ loader: false });
    });
  };
  render() {
    const { loader } = this.state;
    return (
      <Fragment>
        <SamiBanner title={"Contact Us"} subTitle={"contact"} />
        <div class="banner_bottom_agile_info">
          {loader && <Loader />}
          <div class="container">
            <div class="  row">
              <div class="col-md-4 contact-grid-agile-w3">
                <div class="contact-grid-agile-w32">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <h4>Call Us</h4>
                  <p>&nbsp;</p>
                  <p>
                    <span>974 66012724</span>
                  </p>

                  <p>&nbsp;</p>
                </div>
              </div>
              <div class="col-md-6 contact-grid-agile-w3">
                <div class="contact-grid-agile-w33">
                  <i class="fa fa-envelope-o" aria-hidden="true"></i>
                  <h4>Email</h4>
                  <p>&nbsp;</p>
                  <p>
                    <a href="mailto:salescoordinator@arabtechstore.com">
                      salescoordinator@arabtechstore.com
                    </a>
                    {/* <span>
                      <a href="mailto:info@example.com">info@example2.com</a>
                    </span> */}
                  </p>
                  <p>&nbsp;</p>
                  {/* <br /> */}
                </div>
              </div>
              <div class="col-md-6 contact-grid-agile-w3">
                <div class="contact-grid-agile-w31">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  <h4>Address</h4>
                  {/* <p>C Ring Road Doha, Qatar.</p> */}
                  <p>Office No 106, First Floor,</p>
                  <p>Bin Alshaikh Holding Bank Street,</p>
                  <p>Doha, Qatar.</p>
                </div>
              </div>
              <div class="clearfix"> </div>
            </div>
          </div>
        </div>

        <div class="contact-w3-agile1 map" data-aos="flip-right">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21327.94846245829!2d51.517975239872875!3d25.287646665466802!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45dbedfed57ced%3A0x99119bd3fd3e6cb1!2sAudio%20Rental%20Audiobot%20store%20trading%20(Arabtechstore)%2CDoha%20Qatar!5e0!3m2!1sen!2sin!4v1662965369447!5m2!1sen!2sin"
            className="map"
          ></iframe>
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.2248351715853!2d51.52661831538188!3d25.263021035225478!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c545cc7becd3%3A0xcbab840f20b71432!2sC%20Ring%20Rd%2C%20Doha%2C%20Qatar!5e0!3m2!1sen!2sin!4v1600421188880!5m2!1sen!2sin"
            class="map"
            // width="600"
            // height="450"
            // frameborder="0"
            // style="border:0;"
            // allowfullscreen=""
            // aria-hidden="false"
            // tabindex="0"
          ></iframe> */}
        </div>

        <div class="banner_bottom_agile_info">
          <div class="container">
            <div class="agile-contact-grids">
              <div class="agile-contact-left">
                <div class="col-md-6 address-grid">
                  <h4>
                    For More <span>Information</span>
                  </h4>
                  <div class="mail-agileits-w3layouts">
                    <i
                      class="fa fa-volume-control-phone"
                      aria-hidden="true"
                    ></i>
                    <div class="contact-right">
                      <p>Telephone </p>
                      <span>974 66012724</span>
                    </div>
                    <div class="clearfix"> </div>
                  </div>
                  <div class="mail-agileits-w3layouts">
                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                    <div class="contact-right">
                      <p>Mail </p>
                      <a href="mailto:salescoordinator@arabtechstore.com">
                        salescoordinator@arabtechstore.com
                      </a>
                    </div>
                    <div class="clearfix"> </div>
                  </div>
                  <div class="mail-agileits-w3layouts">
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                    <div class="contact-right">
                      <p>Location</p>
                      <span>Office No 106, First Floor,</span>
                      <br />
                      <span>Bin Alshaikh Holding Bank Street,</span> <br />
                      <span>Doha, Qatar.</span>
                    </div>
                    <div class="clearfix"> </div>
                  </div>
                  {/* <ul class="social-nav model-3d-0 footer-social w3_agile_social two contact">
                    <li class="share">SHARE ON : </li>
                    <li>
                      <a href="#" class="facebook">
                        <div class="front">
                          <i class="fa fa-facebook" aria-hidden="true"></i>
                        </div>
                        <div class="back">
                          <i class="fa fa-facebook" aria-hidden="true"></i>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#" class="twitter">
                        <div class="front">
                          <i class="fa fa-twitter" aria-hidden="true"></i>
                        </div>
                        <div class="back">
                          <i class="fa fa-twitter" aria-hidden="true"></i>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#" class="instagram">
                        <div class="front">
                          <i class="fa fa-instagram" aria-hidden="true"></i>
                        </div>
                        <div class="back">
                          <i class="fa fa-instagram" aria-hidden="true"></i>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="#" class="pinterest">
                        <div class="front">
                          <i class="fa fa-linkedin" aria-hidden="true"></i>
                        </div>
                        <div class="back">
                          <i class="fa fa-linkedin" aria-hidden="true"></i>
                        </div>
                      </a>
                    </li>
                  </ul> */}
                </div>
                <div class="col-md-6 contact-form">
                  <h4
                    class="white-w3ls black-w3ls"
                    style={{ color: "#000000 !important" }}
                  >
                    Contact <span>Form</span>
                  </h4>
                  <form onSubmit={this.handleSubmit} method="post">
                    <div class="styled-input agile-styled-input-top">
                      <input type="text" name="name" required="" />
                      <label>Name</label>
                      <span></span>
                    </div>
                    <div class="styled-input">
                      <input type="email" name="email" required="" />
                      <label>Email</label>
                      <span></span>
                    </div>
                    <div class="styled-input">
                      <input type="text" name="subject" required="" />
                      <label>Subject</label>
                      <span></span>
                    </div>
                    <div class="styled-input">
                      <textarea name="message" required=""></textarea>
                      <label>Message</label>
                      <span></span>
                    </div>
                    <input type="submit" value="SEND" />
                  </form>
                </div>
              </div>
              <div class="clearfix"> </div>
            </div>
          </div>
        </div>

        {/* <CouponsGrids /> */}
      </Fragment>
    );
  }
}

export default ContactUs;
