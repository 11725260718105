import React, { Fragment } from "react";
import store from "store";
import { apiURL, apiPrefix } from "../../utlis/config";
import { Link } from "react-router-dom";
import { Collapse, Checkbox, Row, Col } from "antd";
const Panel = Collapse.Panel;

const SideFilter = (propsInfo) => {
  console.log({ propsInfo });
  const {
    isBrand,

    categoryList,
    brandList,
    subCategoryList,
    categoryInfo,
    onBrandChange,
    onCategoryChange,
    onSubCategoryChange,
  } = propsInfo;
  console.log({ propsInfo });
  // const
  return (
    <Fragment>
      {!isBrand && (
        <div class="card  ">
          <div
            class="card-header p-20  p-b-15"
            role="tab"
            id="headingOne"
            style={{ borderBottom: "1px solid #d6d6d6" }}
          >
            <h4 class="mb-0" style={{ fontWeight: 600 }}>
              Brand
            </h4>
          </div>
          <div class="card-body  p-15">
            <Checkbox.Group style={{ width: "100%" }} onChange={onBrandChange}>
              <Row className=" p-l-30 p-r-30">
                {brandList &&
                  brandList.map((post) => {
                    return (
                      <Col span={24} className="p-5">
                        <Checkbox value={post.sUrl}>
                          {" "}
                          &nbsp;&nbsp;{post.name}
                        </Checkbox>
                      </Col>
                    );
                  })}
              </Row>
            </Checkbox.Group>
          </div>
        </div>
      )}
      {!isBrand && (
        <div class="card  ">
          <div
            class="card-header p-l-20 p-r-20 p-t-15 p-b-15"
            role="tab"
            id="headingOne"
            style={{
              borderTop: "1px solid #d6d6d6",
              borderBottom: "1px solid #d6d6d6",
            }}
          >
            <h4 class="mb-0" style={{ fontWeight: 600 }}>
              Sub-Category
            </h4>
          </div>
          <div class="card-body  p-15">
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={onSubCategoryChange}
            >
              <Row className=" p-l-30 p-r-30">
                {subCategoryList &&
                  subCategoryList.length > 0 &&
                  subCategoryList.map((post) => {
                    return (
                      <Col span={24} className="p-5">
                        <Checkbox value={post.label}>
                          {" "}
                          &nbsp;&nbsp;{post.label}
                        </Checkbox>
                      </Col>
                    );
                  })}
              </Row>
            </Checkbox.Group>
          </div>
        </div>
      )}
      {isBrand && (
        <div class="card  ">
          <div
            class="card-header p-20"
            role="tab"
            id="headingOne"
            style={{ borderBottom: "1px solid #d6d6d6" }}
          >
            <h4 class="mb-0" style={{ fontWeight: 600 }}>
              Category
            </h4>
          </div>
          <div class="card-body  p-15">
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={onCategoryChange}
            >
              <Row className=" p-l-30 p-r-30">
                {categoryList.map((post) => {
                  return (
                    <Col span={24} className="p-5">
                      <Checkbox value={post.sUrl}>
                        {" "}
                        &nbsp;&nbsp;{post.name}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SideFilter;
